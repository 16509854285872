// src/App.js
import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import Moralis from "moralis";
import CrossDEXArbitrage from "./CrossDEXArbitrage.json";
// import { useWeb3Modal, useWalletInfo } from "@web3modal/wagmi/react";
// import { useAccount, useDisconnect } from "wagmi";
// import { useAppKit } from '@reown/appkit/react'

// Set up constants for new DEX addresses (can be PancakeSwap, 1inch, etc.)
const UNISWAP_ROUTER_ADDRESS = "0xE592427A0AEce92De3Edee1F18E0157C05861564";
const SUSHISWAP_ROUTER_ADDRESS = "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F";
const PANCAKE_ROUTER_ADDRESS = "0x10ED43C718714eb63d5aA57B78B54704E256024E";

const WETH_ADDRESS = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
const TOKEN_A_ADDRESS = "0x69420E3A3aa9E17Dea102Bb3a9b3B73dcDDB9528";
const TOKEN_B_ADDRESS = "0x73d7c860998CA3c01Ce8c808F5577d94d545d1b4";

const ARBITRAGE_CONTRACT_ADDRESS = "0x69378192bce733c5f72bf88212c32d0a2deb6b11";

const PROFIT_THRESHOLD = 16.01; // Minimum profit threshold in ETH
// Time in ms to wait between price checks

const App = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [router, setRouter] = useState("");
  const [token, setToken] = useState("");

  const [amount, setAmount] = useState("");
  const [arbit, setArbit] = useState("");
  const [msg, setMsg] = useState("Ready to start arbitrage!");
  const [msgSts, setMsgSts] = useState("green");
  const [refreshId, setRefreshId] = useState();
  const [isLoader, setLoder] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [tData, setTradeData] = useState([{}]);
  const [routertxt, setRouterText] = useState("");
  const [tokentxt, setTokenText] = useState("");

  const [profittxt, setProfitText] = useState("");
  const [stustxt, setStasText] = useState("");
  const [prog, setProgress] = useState(0);

  //const { disconnect } = useDisconnect();
  //const { open, close } = useWeb3Modal();
  // const { open } = useAppKit();

  const _pollDataInterval = undefined;

  const POLLING_INTERVAL = 10000000;

  // // Fetch price data from PancakeSwap API or direct contract interaction
  // const getPriceOnPancakeSwap = async (path) => {
  //     // Make an API call to PancakeSwap API to get price info or directly use a contract call
  //     const response = await axios.get(`https://api.pancakeswap.info/api/v2/price/${path[0]}-${path[1]}`);
  //     return response.data.data.price; // Example format, change according to real PancakeSwap API
  // };

  // // Fetch price from Uniswap
  // const getPriceOnUniswap = async (path) => {
  //     const response = await axios.get(`https://api.uniswap.org/quote/v2/${path}`);
  //     return response.raw;
  // };

  // // Fetch price from Sushiswap
  // const getPriceOnSushiswap = async (path) => {
  //     const response = await axios.get(`https://api.sushiswap.org/quote/v2/${path}`);
  //     return response.data.price;
  // };

  // Fetch price from 1inch
  const getPriceOn1inch = async (path) => {
    // const response = await axios.get(`https://api.1inch.io/v4.0/1/quote?fromTokenAddress=${path[0]}&toTokenAddress=${path[1]}&amount=1000000000000000000`);
    // return response.data.toTokenAmount / 1e18; // Price for 1 unit of input token

    try {
      if (!Moralis.Core.isStarted) {
        console.log("1st time moralis start");

        await Moralis.start({
          apiKey:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImNhMzZiZTZkLWQ5NmQtNGY1OS1iNGQ0LTcyNDA5NDFlMjY0NyIsIm9yZ0lkIjoiMjc4MTU1IiwidXNlcklkIjoiMjgzODI2IiwidHlwZUlkIjoiMmMxZjZjYzUtYjc0Ni00MDY3LWEyNWEtOWIxYmE4ZDVhMGNkIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODMxMjAwMTYsImV4cCI6NDgzODg4MDAxNn0.b-GzLAbecYZG6NMi1821irqwcPE1_qfCoLCHWOOQgc0",
        });
      }
      // const response = await Moralis.EvmApi.token.getMultipleTokenPrices({
      //   "chain": "0x1",
      //   "include": "percent_change"
      // },{
      //   "tokens": [
      //     {
      //       "exchange": "uniswapv2",
      //       "tokenAddress": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
      //     },
      //     {
      //       "exchange": "uniswapv3",
      //       "tokenAddress": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
      //     },
      //     {
      //       "exchange": "sushiswapv2",
      //       "tokenAddress": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      //       "toBlock": "16314545"
      //     },
      //     {
      //       "exchange": "uniswapv3",
      //       "tokenAddress": "0x69420E3A3aa9E17Dea102Bb3a9b3B73dcDDB9528"
      //     }
      const response = await Moralis.EvmApi.token.getMultipleTokenPrices(
        {
          chain: "0x38", //0x38
          include: "percent_change",
        },
        {
          tokens: [
            {
              exchange: "pancakeswapv1",
              tokenAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            },
            {
              exchange: "uniswapv3",
              tokenAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            },
            {
              exchange: "pancakeswapv2",
              tokenAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            },
            {
              exchange: "pancakeswapv3",
              tokenAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            },
          ],
        }
      );
      //  console.log(response.raw);  0x2170Ed0880ac9A755fd29B2688956BD959F933F8
      //  console.log(response.raw[0].usdPrice);
      return response.raw;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  // Function to identify arbitrage opportunities
  const findArbitrageOpportunity = async () => {
    //var vb = tData;
    //setTradeData(vb);
    //console.log(vb);
    // console.log("arbi hits");
    // if (arbit == "Start") {
    const path1 = [WETH_ADDRESS, TOKEN_A_ADDRESS];
    const path2 = [TOKEN_A_ADDRESS, TOKEN_B_ADDRESS];
    const path3 = [TOKEN_B_ADDRESS, WETH_ADDRESS];

    try {
      console.log(WETH_ADDRESS);
      const dd = await getPriceOn1inch(WETH_ADDRESS);
      console.log(dd);

      // Fetch prices from all DEXs
      const uniswapPrice1 = dd[0].usdPrice;
      const sushiswapPrice2 = dd[1].usdPrice; //await getPriceOnSushiswap(path2);
      const pancakePrice3 = dd[2].usdPrice; //await getPriceOnPancakeSwap(path3);
      const oneInchPrice3 = dd[3].usdPrice; // await getPriceOn1inch(path3);

      console.log("pancakeswapv1 : " + uniswapPrice1);
      console.log("uniswapv3 : " + sushiswapPrice2);
      console.log("pancakeswapv2 : " + pancakePrice3);
      console.log("pancakeswapv3 : " + oneInchPrice3);

      const mx = Math.max(
        pancakePrice3,
        oneInchPrice3,
        uniswapPrice1,
        sushiswapPrice2
      );
      const mi = Math.min(
        pancakePrice3,
        oneInchPrice3,
        uniswapPrice1,
        sushiswapPrice2
      );

      console.log("Max : " + mx);
      console.log("Min : " + mi);

      const profit1 = mx - mi;
      console.log("profit : " + profit1);

      // Calculate arbitrage opportunity for each combination
      const initialETH = 3000; // Example starting with 1 ETH
      const tokenA = initialETH * uniswapPrice1;
      const tokenB = tokenA * sushiswapPrice2;
      const finalETH = tokenB * Math.max(pancakePrice3, oneInchPrice3); // Choose best route (Pancake vs 1inch)

      // Profit calculation
      const profit = finalETH - initialETH;
      console.log("Calculated Profit: ", profit);

      // tData.push({
      //   router: routertxt,
      //   token: tokentxt,
      //   profit: profit1.toFixed(4),
      //   stus: "Profit",
      // });

      // //var jkl = df;

      // setTradeData(tData);

      // Check if profit meets the threshold
      var pft = profit1 - 15;
      if (profit1 > PROFIT_THRESHOLD) {
        console.log("Profitable arbitrage opportunity found!");
        setProfitText(pft.toFixed(4));
        setStasText("Gain");
        //await executeArbitrageNew(initialETH);
      } else {
        console.log("No profitable arbitrage found.");
        setProfitText(pft.toFixed(4));
        setStasText("Loss");
      }
    } catch (error) {
      console.log("Error finding arbitrage opportunity:", error);
    }
    // } else {
    //   setMsgSts("crimson");
    //   setMsg("Arbitrage Bot has stoped!");
    // }
  };

  // Execute arbitrage trade on the smart contract OLD
  // const executeArbitrage = async (amountIn) => {
  //     const contract = new web3.eth.Contract(
  //         [
  //             // Your contract's ABI goes here
  //             // Example:
  //             // { "constant": false, "inputs": [...], "name": "executeArbitrage", "outputs": [], "type": "function" }
  //         ],
  //         ARBITRAGE_CONTRACT_ADDRESS
  //     );

  //     const path1 = [WETH_ADDRESS, TOKEN_A_ADDRESS];
  //     const path2 = [TOKEN_A_ADDRESS, TOKEN_B_ADDRESS];
  //     const path3 = [TOKEN_B_ADDRESS, WETH_ADDRESS];

  //     try {
  //         const receipt = await contract.methods
  //             .executeArbitrage(
  //                 UNISWAP_ROUTER_ADDRESS,
  //                 SUSHISWAP_ROUTER_ADDRESS,
  //                 path1,
  //                 path2,
  //                 path3,
  //                 web3.utils.toWei(amountIn.toString(), "ether")
  //             )
  //             .send({ from: account });
  //         console.log("Arbitrage transaction successful:", receipt);
  //     } catch (error) {
  //         console.error("Arbitrage transaction failed:", error);
  //     }
  // };

  async function executeArbitrageNew(
    buyRouter,
    sellRouter,
    pathBuy,
    pathSell,
    amountIn,
    minAmountOutBuy,
    minAmountOutSell,
    deadline,
    provider
  ) {
    const _provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = _provider.getSigner();
    const arbitrageContract = new ethers.Contract(
      ARBITRAGE_CONTRACT_ADDRESS,
      CrossDEXArbitrage.abi,
      signer
    );

    try {
      const tx = await arbitrageContract.executeArbitrage(
        buyRouter,
        sellRouter,
        pathBuy,
        pathSell,
        ethers.utils.parseUnits(amountIn, 18), // Convert input amount to wei
        ethers.utils.parseUnits(minAmountOutBuy, 18), // Min amount of `pathBuy` output token
        ethers.utils.parseUnits(minAmountOutSell, 18), // Min amount of `pathSell` output token
        deadline
      );

      await tx.wait();
      console.log("Arbitrage trade executed successfully!");
    } catch (error) {
      console.error("Arbitrage execution failed:", error);
    }
  }

  const _initializeEthers = async () => {
    // We first initialize ethers by creating a provider using window.ethereum

    const _provider = new ethers.providers.Web3Provider(window.ethereum);
    _provider.getSigner(0);

    //console.log(_provider.getSigner(0));
    // Then, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.

    //console.log(this._provider)

    // _token = new ethers.Contract(
    //   contractAddress.Token,
    //   tokenABI.abi,
    //   _provider.getSigner(0)
    // );
  };
  const _approved = async () => {
    const [selectedAddress] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    console.log(selectedAddress);

    setMsg("");
    var rotr = document.getElementById("ddlRouter").value;
    var tkn = document.getElementById("ddlToken").value;

    const selectedIndexRouter =
      document.getElementById("ddlRouter").selectedIndex;
    const selectedOptionRouter =
      document.getElementById("ddlRouter").options[selectedIndexRouter];

    const selectedIndexToken =
      document.getElementById("ddlToken").selectedIndex;
    const selectedOptionToken =
      document.getElementById("ddlToken").options[selectedIndexToken];

    var aprvd = "1000000000000000000"; //document.getElementById("txtAmount").value;
    //console.log(tkn + "  - " + rotr + "  -  " + aprvd);
    if (rotr != "0" && tkn != "0" && aprvd != null && selectedAddress != "") {
      setRouter(rotr);
      setToken(tkn);
      setAmount(aprvd);
      setRouterText(selectedOptionRouter.text);
      setTokenText(selectedOptionToken.text);

      setMsgSts("green");
      setMsg("Arbitrage Approved");
      setArbit("Start");
      // setLoder(true);
      // const _provider = new ethers.providers.Web3Provider(window.ethereum);
      // const signer = _provider.getSigner();
      // const arbitContract = new ethers.Contract(
      //   ARBITRAGE_CONTRACT_ADDRESS,
      //   CrossDEXArbitrage.abi,
      //   signer
      // );
      // console.log(tkn + "  - " + rotr + "  -  " + aprvd);

      // const tx = await arbitContract.approveTokens(tkn, rotr, aprvd);

      // await tx.wait();
      // const receipt = await tx.wait();
      // if (receipt.status === 0) {
      //   setLoder(false);
      //   console.log("Transaction Failed!");
      // } else {
      //   setLoder(false);
      //   console.log("Transaction Success!");
      // }
    } else {
      setMsgSts("crimson");
      setMsg("Invalid Input Request!");
    }
  };
  const _startArbitrage = async () => {
    setMsgSts("green");
    setMsg("Arbitrage Started");
    setArbit("Start");
    // _pollDataInterval = setInterval(function () {
    //   findArbitrageOpportunity();
    // }, 100000);
    var timCount = 1;
    const myInterval = setInterval(function () {
      //console.log(timCount);
      if (timCount == 60) {
        timCount = 0;
        findArbitrageOpportunity();
      }
      setProgress(timCount);
      timCount++;
    }, 1000);
    setRefreshId(myInterval);
  };
  const _stopArbitrage = async () => {
    setArbit("Stop");
    setMsgSts("crimson");
    setMsg("Arbitrage Stoped");
    //clearInterval(_pollDataInterval);
    clearInterval(refreshId);
    //console.log(arbit);
  };

  const _walletConnect = async () => {
    const [selectedAddress] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    //console.log(selectedAddress);
    setSelectedAddress(selectedAddress);
  };

  const _executeArbitrage = async () => {
    const [selectedAddress] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    console.log(selectedAddress);

    const _provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = _provider.getSigner();
    const arbitContract = new ethers.Contract(
      ARBITRAGE_CONTRACT_ADDRESS,
      CrossDEXArbitrage.abi,
      signer
    );
    var buyRouter = "0xE592427A0AEce92De3Edee1F18E0157C05861564";
    var sellRouter = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
    var pathBuy = [
      "0x55d398326f99059ff775485246999027b3197955",
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    ];
    var pathSell = [
      "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "0x55d398326f99059ff775485246999027b3197955",
    ];
    var amountIn = ethers.utils.parseUnits("1", 18); //"002868505383068454";
    var minAmountOutBuy = ethers.utils.parseUnits("0.9", 18);
    var minAmountOutSell = ethers.utils.parseUnits("1.1", 18);
    var deadline = Math.floor(Date.now() / 1000) + 60 * 10;

    console.log(buyRouter);
    console.log(sellRouter);
    console.log(pathBuy);
    console.log(pathSell);
    console.log(amountIn);
    console.log(minAmountOutBuy);
    console.log(minAmountOutSell);
    console.log(deadline);

    const tx = await arbitContract.executeArbitrage(
      buyRouter,
      sellRouter,
      pathBuy,
      pathSell,
      amountIn, // Convert input amount to wei
      minAmountOutBuy, // Min amount of `pathBuy` output token
      minAmountOutSell, // Min amount of `pathSell` output token
      deadline,
      { gasLimit: ethers.utils.hexlify(2000000) }
    );

    //   await executeArbitrage(
    //     buyRouter,
    //     sellRouter,
    //     pathBuy,
    //     pathSell,
    //     "1.0",                   // amountIn (1 WETH in wei)
    //     "0.99",                  // minAmountOutBuy (min DAI expected from buy)
    //     "1.01",                  // minAmountOutSell (min WETH expected from sell)
    //     Math.floor(Date.now() / 1000) + 60 * 10, // deadline in 10 minutes
    //     provider                // ethers provider
    // );

    await tx.wait();
    const receipt = await tx.wait();
    if (receipt.status === 0) {
      //setLoder(false);
      console.log("Transaction Failed!");
    } else {
      //setLoder(false);
      console.log("Transaction Success!");
    }
  };

  useEffect(() => {
    // async function setupWeb3() {
    //     const web3Instance = new Web3(Web3.givenProvider || "https://polygon-mainnet.infura.io");
    //     const accounts = await web3Instance.eth.requestAccounts();
    //     setWeb3(web3Instance);
    //     setAccount(accounts[0]);
    // }
    //console.log('Hit');
    //setupWeb3();
    //console.log(POLLING_INTERVAL);
    // _initializeEthers();
    //open();
    // const interval = setInterval(findArbitrageOpportunity(), 20000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ width: "100%", padding: 14 }}>
      <div style={{ width: "90%" }}>
        <div style={{ float: "left" }}>
          <span style={{ fontSize: 26, color: "darkorchid" }}>Trading Bot</span>
        </div>
        <div style={{ float: "right" }}>
          {selectedAddress == "" && (
            <input
              type="button"
              id="btnConnect"
              style={{
                width: "100%",
                height: 30,
                backgroundColor: "yellowgreen",
                color: "black",
                borderRadius: 4,
              }}
              value={"Wallet Connect"}
              onClick={() => _walletConnect()}
            ></input>
          )}
          {selectedAddress != "" && (
            <span>Wallet Address: {selectedAddress}</span>
          )}
        </div>
      </div>
      {msg != "" && (
        <div
          style={{
            width: "90%",
            marginTop: "20%",
            backgroundColor: msgSts,
            borderRadius: 6,
            padding: 6,
          }}
        >
          <div style={{ width: "100%" }}>
            <span style={msg != "" ? { color: "white" } : { color: "white" }}>
              {msg}
            </span>
          </div>
        </div>
      )}
      {isLoader == true && (
        <div
          style={{
            marginTop: "40%",
            marginLeft: "44%",
            zIndex: 9999,
            position: "fixed",
          }}
        >
          <div>
            <img
              src={require("../src/wait.gif")}
              className="d-flex img-fluid"
              //style={{ marginLeft: "30%" }}
              width={50}
            ></img>
          </div>
        </div>
      )}
      <div
        style={{
          width: "90%",
          marginTop: "10%",
          backgroundColor: "grey",
          borderRadius: 6,
          padding: 6,
        }}
      >
        <div style={{ marginTop: 20, width: "100%" }}>
          <span>Router</span>
        </div>
        <div style={{ marginTop: 5, width: "100%" }}>
          {/* <input type="text" id="txtRouter" style={{ width: 300 }}></input> */}
          <select id="ddlRouter" style={{ width: "92%", height: 30 }}>
            <option value={"0"}>Choose Router</option>
            <option value={"0xE592427A0AEce92De3Edee1F18E0157C05861564"}>
              UNISWAP V2
            </option>
            <option value={"0xC6665d98Efd81f47B03801187eB46cbC63F328B0"}>
              PANCAKE V1
            </option>
            <option value={"0x10ED43C718714eb63d5aA57B78B54704E256024E"}>
              PANCAKE V2
            </option>
            <option value={"0x1b81D678ffb9C0263b24A97847620C99d213eB14"}>
              PANCAKE V3
            </option>
          </select>
        </div>
        <div style={{ marginTop: 5, width: "100%" }}>
          <span>Token</span>
        </div>
        {/* <div style={{ marginTop: 5, width: "100%" }}>
          <input
            type="text"
            id="txtToken"
            placeholder="Enter Token"
            style={{ width: "90%", height: 30 }}
          ></input>
        </div> */}
        <div style={{ marginTop: 5, width: "100%" }}>
          {/* <input type="text" id="txtRouter" style={{ width: 300 }}></input> */}
          <select id="ddlToken" style={{ width: "92%", height: 30 }}>
            <option value={"0"}>Choose Toekn</option>
            <option value={"0x2170Ed0880ac9A755fd29B2688956BD959F933F8"}>
              WETH
            </option>
            <option value={"0x55d398326f99059ff775485246999027b3197955"}>
              USDT
            </option>
          </select>
        </div>
        <div style={{ marginTop: 5, width: "100%" }}>
          <span>Amount</span>
        </div>
        <div style={{ marginTop: 8, width: "100%" }}>
          <input
            type="text"
            id="txtAmount"
            placeholder="Enter Amount"
            style={{ width: "90%", height: 30 }}
          ></input>
        </div>
        <div style={{ marginTop: 12, marginBottom: 10, width: "100%" }}>
          <input
            type="button"
            id="btnApproved"
            style={{
              width: "90%",
              height: 30,
              backgroundColor: "green",
              color: "white",
              borderRadius: 4,
            }}
            value={"Approved"}
            onClick={() => _approved()}
          ></input>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          marginTop: "2%",
          backgroundColor: "grey",
          borderRadius: 6,
          padding: 6,
        }}
      >
        <div style={{ marginTop: 12, marginBottom: 10, width: "100%" }}>
          <input
            type="button"
            id="btnStart"
            style={{
              width: "90%",
              height: 30,
              backgroundColor: "skyblue",
              color: "black",
              borderRadius: 4,
            }}
            value={"Start"}
            onClick={() => _startArbitrage()}
          ></input>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          marginTop: "10%",
          backgroundColor: "grey",
          borderRadius: 6,
          padding: 6,
        }}
      >
        <div style={{ marginTop: 12, marginBottom: 10, width: "100%" }}>
          <input
            type="button"
            id="btnStop"
            style={{
              width: "90%",
              height: 30,
              backgroundColor: "red",
              color: "white",
              borderRadius: 4,
            }}
            value={"Stop"}
            onClick={() => _stopArbitrage()}
          ></input>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          marginTop: "10%",
          backgroundColor: "grey",
          borderRadius: 6,
          padding: 6,
          display: "none",
        }}
      >
        <div style={{ marginTop: 12, marginBottom: 10, width: "100%" }}>
          <input
            type="button"
            id="btnExecute"
            style={{
              width: "90%",
              height: 30,
              backgroundColor: "red",
              color: "white",
              borderRadius: 4,
            }}
            value={"Execute"}
            onClick={() => _executeArbitrage()}
          ></input>
        </div>
      </div>
      {msg === "Arbitrage Started" && (
        <div style={{ width: "90%", marginTop: "10%" }}>
          <img src={require("./GGIF.gif")}></img>
          <progress value={prog} max={60} style={{ width: "100%" }} />
        </div>
      )}
      <div
        style={{
          width: "90%",
          marginTop: "3%",
          backgroundColor: "grey",
          borderRadius: 6,
          padding: 6,
          height: 120,
        }}
      >
        <div style={{ fontWeight: "bold" }}>Last Trade Finds:</div>
        <div style={{ marginBottom: 20, marginTop: 10, lineHeight: 1 }}>
          <div style={{ width: "25%", float: "left" }}>
            <span style={msg != "" ? { color: "white" } : { color: "white" }}>
              {"Router"}
            </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={msg != "" ? { color: "white" } : { color: "white" }}>
              {"Token"}
            </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={msg != "" ? { color: "white" } : { color: "white" }}>
              {"Profit"}
            </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={msg != "" ? { color: "white" } : { color: "white" }}>
              {"Status"}
            </span>
          </div>
        </div>
        {/* {selectedAddress != "" && tData.map((r, index) => {
          return ( */}
        <div style={{ marginBottom: 20, lineHeight: 2 }}>
          <div style={{ width: "25%", float: "left" }}>
            <span style={msg != "" ? { color: "white" } : { color: "white" }}>
              {routertxt}
            </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={msg != "" ? { color: "white" } : { color: "white" }}>
              {tokentxt}
            </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span
              style={stustxt != "Gain" ? { color: "red" } : { color: "green" }}
            >
              {profittxt}
            </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span
              style={stustxt != "Gain" ? { color: "red" } : { color: "green" }}
            >
              {stustxt}
            </span>
          </div>
        </div>
        {/* );
        })} */}
      </div>
    </div>
  );
};

export default App;
